import { createSignal } from 'solid-js';
import { ENGLISH_CODE } from '~/assets/strings';
import enTranslations from '~/locales/en/translation.json';
import mxTranslations from '~/locales/mx/translation.json';
import zhTranslations from '~/locales/zh/translation.json';

interface Translation {
  [key: string]: string;
}

interface TranslationsDictionary {
  [langCode: string]: Translation;
}

const dictionary: TranslationsDictionary = {
  en: enTranslations,
  mx: mxTranslations,
  zh: zhTranslations,
};

const getInitialLocale = (): string => {
  if (typeof window !== 'undefined' && localStorage.getItem('locale')) {
    return localStorage.getItem('locale') as string;
  }
  return ENGLISH_CODE;
};

const [currentLanguage, setCurrentLanguage] = createSignal<string>(getInitialLocale());

export const useLocalization = () => {
  const changeLanguage = (toLocale?: string): void => {
    const newLocale = toLocale || ENGLISH_CODE;
    if (typeof window !== 'undefined') {
      localStorage.setItem('locale', newLocale);
    }
    setCurrentLanguage(newLocale);
  };

  const t = (key?: string, params?: Record<string, any>): string => {
    if (!key) return '';
    let translation = dictionary[currentLanguage()][key] || key;
    if (params) {
      Object.entries(params).forEach(([paramKey, paramValue]) => {
        const regex = new RegExp(`{${paramKey}}`, 'g');
        translation = translation.replace(regex, paramValue.toString());
      });
    }
    return translation;
  };

  return { t, changeLanguage, currentLanguage };
};